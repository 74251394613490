<template>
  <div>
    <div v-if="showReservationsTable" class="admin-reservation-rooms">
      <ReservationsTable title="Room Reservations To Accept"
                  :list="reservationList" :fields="fields" :loading="loading" :availabilityButton="true"
                  @update-list="update" @open-modal="openModal" @show-availability="showReservationsTable = false"/>
      <ReservationModal @update="update" :type="accommodations.ROOM"/>
    </div>
    <div v-else>
      <AdminAvailabilityTable title="All Rooms Availability"
                              :list="roomList" :loading="loading" :manageAvailability="false"
                              @update-list="update" @show-reservations="showReservationsTable=true"/>
    </div>
  </div>
</template>

<script>
import ReservationsTable from '@/components/admin/ReservationsTable'
import AdminAvailabilityTable from '@/components/admin/AdminAvailabilityTable'
import ReservationModal from '@/components/admin/reservations/ReservationModal'
import { adminService, roomService } from '@/services/admin'
import { accommodations, reservationStatus } from '@/enums'
import { Reservation } from '@/models/reservation.js'

export default {
  name: 'AdminRoomReservations',
  components: {
    ReservationsTable,
    AdminAvailabilityTable,
    ReservationModal,
  },
  data() {
    return {
      accommodations,
      reservationList: [],
      roomList: [],
      loading: false,
      showReservationsTable: true
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', label: 'ID' },
        { key: 'buildingName', label: 'Building' },
        { key: 'name', label: 'Room' },
        { key: 'roomType.name', label: 'Type' },
        { key: 'startDate', label: 'From', type: 'date' },
        { key: 'expiringDateAux', label: 'To', type: 'date' },
        { key: 'firstname', label: 'Firstname' },
        { key: 'lastname', label: 'Lastname' },
        { key: 'email', label: 'Email' },
        { key: 'status' },
        { key: 'actions', label: 'Actions', type: 'actions', actions: ['show'] },
      ]
    },
  },
  async beforeMount() {
    await this.update()
  },
  methods: {
    openModal(item) {
      this.$store.commit('adminStore/setReservation', item)
      this.$root.$emit('bv::show::modal', 'reservation-modal')
    },
    async update() {
      this.loading = true
      this.reservationList = []
      let [reservationList, roomList] = await Promise.all([
        adminService.getReservationList(reservationStatus.COMPLETED, accommodations.ROOM.toUpperCase()),
        roomService.getRooms()
      ])
      reservationList.forEach(element => {
        this.reservationList.push(new Reservation(element))
      });
      this.roomList = roomList
      this.loading = false
    }
  }
}
</script>

<style>

</style>
